import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-030279bf"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "main_div pb-1" };
var _hoisted_2 = { class: "d-flex flex-column flex-column-fluid" };
var _hoisted_3 = { class: "p-10" };
var _hoisted_4 = { class: "d-flex flex-column text-center" };
var _hoisted_5 = ["src"];
var _hoisted_6 = { class: "d-flex flex-column text-success fw-bolder my-0 fs_22" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    var _component_KTLoader = _resolveComponent("KTLoader");
    var _component_router_link = _resolveComponent("router-link");
    var _component_router_view = _resolveComponent("router-view");
    var _component_KTFooter = _resolveComponent("KTFooter");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.loaderEnabled)
            ? (_openBlock(), _createBlock(_component_KTLoader, {
                key: 0,
                logo: _ctx.loaderLogo
            }, null, 8, ["logo"]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_router_link, {
                            to: { name: 'home' },
                            class: "mb-3"
                        }, {
                            default: _withCtx(function () {
                                var _a;
                                return [
                                    _createElementVNode("img", {
                                        alt: "Logo",
                                        src: (_a = _ctx.currentStore) === null || _a === void 0 ? void 0 : _a.store_logo,
                                        class: "h-90px rounded-circle"
                                    }, null, 8, _hoisted_5)
                                ];
                            }),
                            _: 1
                        }),
                        _createElementVNode("h1", _hoisted_6, _toDisplayString((_a = _ctx.currentStore) === null || _a === void 0 ? void 0 : _a.store_name), 1)
                    ]),
                    _createVNode(_component_router_view, { class: "mt-5" }, {
                        default: _withCtx(function (_a) {
                            var Component = _a.Component;
                            return [
                                _createVNode(_Transition, { name: "fade" }, {
                                    default: _withCtx(function () { return [
                                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                                    ]; }),
                                    _: 2
                                }, 1024)
                            ];
                        }),
                        _: 1
                    })
                ])
            ])
        ]),
        _createVNode(_component_KTFooter, { class: "fixed-bottom bg-white" })
    ], 64));
}
