import { computed, defineComponent, nextTick, onMounted, onUnmounted, } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";
import KTLoader from "@/components/Loader.vue";
import { loaderEnabled, loaderLogo } from "@/core/helpers/config";
import { reinitializeComponents } from "@/core/plugins/keenthemes";
import KTFooter from "@/layout/footer/Footer.vue";
export default defineComponent({
    name: "auth",
    components: { KTLoader: KTLoader, KTFooter: KTFooter },
    setup: function () {
        var store = useStore();
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        var currentStore = computed(function () {
            return store.getters.getStoreData;
        });
        onMounted(function () {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
            nextTick(function () {
                reinitializeComponents();
            });
            setTimeout(function () {
                store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            }, 250);
        });
        onUnmounted(function () {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });
        return {
            getIllustrationsPath: getIllustrationsPath,
            currentStore: currentStore,
            loaderLogo: loaderLogo,
            loaderEnabled: loaderEnabled,
        };
    },
});
